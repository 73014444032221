<template>
    <div class="content">
        <div class="wrapper">
            <div class="card">
                <div class="greeting">
                    <span class="logo" />
                    <h2>Welcome back! Enter your details below to sign in and access your dashboard.</h2>
                </div>
                <div class="form">
                <form action="#" @submit.prevent="submit">
                    <input
                        id="email"
                        type="email"
                        placeholder="E-mail address"
                        name="email"
                        autocomplete="username"
                        value
                        required
                        autofocus
                        v-model="form.email"
                        class="transition"
                    />
                    <input
                        id="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        autocomplete="current-password"
                        required
                        v-model="form.password"
                        class="transition"
                    />
                    <div class="options">
                        <div class="v-center">
                            <input id="remember-me" name="remember-me" type="checkbox" class="checkbox">
                            <label for="remember-me">Remember me</label>
                        </div>
                        <a class="pointer v-center">Forgot your password?</a>
                    </div>
                    <button type="submit" class="transition">Sign in</button>
                </form>
                </div>
                <div v-if="error" class="errormsg">{{error}}</div>
                <div class="info">
                    <span>Don't have an account? <a class="painted bold pointer">Request one now.</a></span>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.errormsg {
    font-size: 0.875rem;
    display: block;
    margin: 0 auto 0.75rem auto;
    color: rgb(207, 42, 50);
}

.content {
    background: rgb(245, 240, 238); /* rgb(255, 241, 234); */
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.card {
    background: rgba(255, 255, 255, 1);
    width: 20rem;
    padding: 2rem;
    border-radius: 1rem;
}

.logo {
    display: block;
    height: 2.25rem;
    background: url('../assets/applogo_black.svg') center center no-repeat;
    background-size: contain;
    margin: 0 auto 1rem auto;
}

.greeting {
    display: block;
    margin: 0 auto 0.75rem auto;
}

.greeting h1 {
    margin: 0 0 0.625rem 0;
    font-size: 1.5rem;
}

.greeting h2 {
    margin: 0;
    font-size: 0.8rem;
    font-weight: normal;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.options {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    margin: 0 auto 1rem auto;
    font-size: 0.8125rem;
    font-weight: normal;
}

.form input[type=email],[type=password] {
    font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
    border: 1px solid rgba(0, 0, 0, 0.45);
    outline: transparent;
    margin: 1rem 0;
    padding: 0.625rem;
    border-radius: 0.5rem;
    font-size: 0.8125rem;
    box-sizing: border-box;
    width: 20rem;
    display: block;
}

.form input:focus {
    box-shadow: 0px 0px 0px 0.1875rem rgba(255, 65, 44, 0.15);
    border: 1px solid rgb(238, 123, 144);
}

.checkbox {
    width: 1rem;
    height: 1rem;
    border-radius: 25%;
    border: 1px solid rgba(0, 0, 0, 0.45);
    display: inline-block;
    margin: 0 0.5rem 0 0;
    outline: transparent;
    appearance:none;
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkmark input:checked ~ .checkmark {
    background-color: rgb(238, 123, 144);
}

.charkmark input:checked ~ .checkmark:after {
    display: block;
}

.info {
    margin: 0.75rem auto 0 auto;
    font-size: 0.8rem;
    font-weight: normal;
}

.form button {
    font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
    font-size: 0.8125rem;
    border: none;
    cursor: pointer;
    font-weight: bold;
    text-decoration: none;
    background: rgba(0,0,0,1);
    padding: 0.750rem;
    border-radius: 0.5rem;
    outline: transparent;
    color: rgba(255,255,255,1);
    float: none;
    user-select: none;
    width: 100%;
    flex-grow: 1;
    margin: 0 0 1rem 0;
}

.mainbutton {
    background-image: linear-gradient(135deg, rgb(255, 171, 102) 0%, rgb(244, 117, 255) 100%) !important;
}

.form button:active {
    box-shadow: 0px 0px 0px 0.125rem rgba(255, 255, 255, 1), 0px 0px 0px 0.25rem rgba(255, 65, 44, 0.35);
}

body {
    margin: 0;
    padding: 0;
    user-zoom: none;
    background-color: rgb(198, 155, 135);
    font-family: 'Jetbrains Mono', 'Courier New', Helvetica, Arial, sans-serif;
}
</style>

<script>
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebasedb";

export default {
  data() {
    return {
      form: {
        email: "",
        password: ""
      },
      error: null
    };
  },
  methods: {
    submit() {
        signInWithEmailAndPassword(auth, this.form.email, this.form.password)
        .then((userCredential) => {
          this.$router.replace({ path: "/dashboard" });
          console.log(userCredential)
        })
        .catch(err => {
          this.error = String(err.message).replace("Firebase: ","");
        });
    }
  }
};
</script>